import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><em parentName="p">{`(Rest Day)`}</em></p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`Today we’re having our annual Halloween WOD!  Come to workout in
your best costume, there will be prizes!  Free to all so you can bring a
friend as well.`}</em></strong></p>
    <p>{`1 Lap Run`}</p>
    <p>{`100 Single Unders`}</p>
    <p>{`75 Situps`}</p>
    <p>{`60 KBS (53/35)`}</p>
    <p>{`45 Pushups`}</p>
    <p>{`30 Ring Rows`}</p>
    <p>{`15 Calorie Row`}</p>
    <p><em parentName="p">{`*`}{`There will be 2 groups, Alive and Zombies.`}</em></p>
    <p><em parentName="p">{`Alive group starts the wod 3:00 before the Zombies.`}</em></p>
    <p><em parentName="p">{`Zombies must catch at least one Alive person of there is a 25 burpee
penalty after the wod.`}</em></p>
    <p><em parentName="p">{`If you are an Alive person and get caught by any Zombie you must do the
25 burpee penalty.`}</em></p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`November 17th-18th CrossFit the Ville will be hosting a USAW
Weightlifting Level 1 Coaching Certification Course!  The Scope and
Sequence of this course includes the teaching progressions of the
Snatch, Clean & Jerk, and all associated movements.  Participants will
gain knowledge and experience of programming of training for both
competitive weightlifters and for athletes using the weightlifting
movements for strength and power development as it applies to their
chosen sport.  `}</em></strong></p>
    <p><strong parentName="p"><em parentName="strong">{`Lead Instructor: Mark Cannella, President and Head Coach of Columbus
Weightlifting in Columbus, Ohio.  He has been part of the lifting
community as an athlete, coach, administrator, and official for over 29
years.`}</em></strong></p>
    <p><strong parentName="p"><em parentName="strong">{`This 2 day course is \\$499.  Those who complete this course will be
awarded their USAW Level 1 Coaching Certificate.  `}</em></strong></p>
    <p><strong parentName="p"><em parentName="strong">{`For more information contact Daniel\\@crossfittheville.org or`}</em></strong></p>
    <p><strong parentName="p"><em parentName="strong">{`Mark Cannella at mark\\@columbusweightlifting.org.`}</em></strong></p>
    <p><strong parentName="p"><em parentName="strong">{`Click the link below to register: `}</em></strong></p>
    <p><a parentName="p" {...{
        "href": "https://webpoint.usaweightlifting.org/wp15/Events2/Registrations/Register.wp?rgs_EventID=168977"
      }}>{`https://webpoint.usaweightlifting.org/wp15/Events2/Registrations/Register.wp?rgs_EventID=168977`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      